import React, { useEffect, useState } from 'react'
import {toast } from 'react-toastify'
import {  cancelActionAPI, cancelOrderAPI, getrobotStatus, robotactionapi, UpdateRobotAction } from '../API'
import ringer from "../components/alert.wav";
import CancelActionModel from './CancelActionModel';
import RobotActionModal from './RobotActionModal';
import io from 'socket.io-client';
function RobotActions(props) {
  
  let socket
  // const serverUrl = 'http://localhost:3000';
  // const serverUrl ="https://goggoapi.ottonomy.io/"
  const audio = new Audio(ringer);
  // audio.loop = true;
  const playbeepsound=()=>{
    function play(){
      audio.play() 
      audio.loop = true
      audio.muted =false
      // console.log(';sdsbfbndn audio')
    } 
     play() 
   }
   const pausebeepsound=()=>{
    function pause(){
      audio.pause() 
      audio.loop = false
      audio.muted =true
      audio.src=''
      // console.log('ELement',document.getElementsByTagName('audio')[0].attributes('src'))
      // console.log('sdsbfbndn pause',audio)
    } 
      pause() 
   } 

  const token = localStorage.getItem('token')
  const [robotData, setrobotData] =useState()
  // const robotId = localStorage.getItem('robotId')
  var robotId=props.robotId
  const[commandlistdata, setcommandlistdata] =useState()
  const [orderId, setorderId] = useState()
  const [actionId, setactionId] =useState()
  const [finishbtn,setfinishbtn] =useState(true)

  useEffect(()=>{
    // socket = io(serverUrl,{transports:["websocket", "polling"]});
    // if(socket)
    //   {  console.log("socket start");
    //   socket.on("receiveGreet",data=>{
    //     console.log(data)
    //   })  
    //   socket.on('robotCommandChange', (data) => {
    //     console.log('robotCommandChange',data)
    //     setcommandlistdata([data])
    // });
    // }
  let finalrobotId =robotId 
    setInterval(()=>{
  
      robotactionapi(finalrobotId,token)
      .then((res)=>{
        // console.log('CommandList Data', res)
        if(res.data.message ==='Malformed request' || res.data ==='' ||res.status ===205 || res.status===204){
          return null
        } 
        else{
          setcommandlistdata(res.data.robotCommandList)
        }               
        let orderiii= res.data.robotCommandList[0].orderId
        let actionId =  res.data.robotCommandList[0].actionId
        setorderId(orderiii)
        setactionId(actionId)
      })
      .catch((err)=>{
        // console.log('Robot Actions Error' ,err)
      })
    }, 5000)

    
  },[robotId])



 const getrobotactionStatus=(commandlistdata,orderId,actionId)=>{
  // console.log('commandlistdata?.actionStatus',commandlistdata?.actionStatus)

  // console.log('commandlistdata?.actionStatus',commandlistdata?.actionStatus)
switch(commandlistdata?.actionStatus){
    case "0" :
      // playbeepsound()
      return ( <div className='RobotActions-button-wrapper'>
                <audio preload="auto" loop autoplay="autoplay" id="playaudio">
                  <source src="/static/media/alert.aa9b8554cbeaf7968e81.wav" type="audio/wav"></source>
              </audio>
                 <RobotActionModal name="Start" actionId={actionId} orderId={orderId} number="1" robotId={props.robotId} commandlistdata={commandlistdata}/>
              {/* <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("1" , actionId,orderId)}>Start Action</button> */}
              {/* <button className="RobotActions-cancel-btn" onClick={()=> handleCancelActionbutton(props?.robotData?.robotStatus?.actionId,orderId)}> Cancel Action</button>*/}
                <CancelActionModel actionId={props?.robotData?.robotStatus?.actionId}  orderId={orderId} fleetId={props?.robotData?.robotStatus?.fleetId} name="Cancel" commandlistdata={commandlistdata}/> 
          </div>);
    case null:
      // playbeepsound()
      return ( <div className='RobotActions-button-wrapper'>
                  <audio preload="auto" loop autoplay="autoplay" id="playaudio">
                      <source src="/static/media/alert.aa9b8554cbeaf7968e81.wav" type="audio/wav"></source>
                  </audio>
                  <RobotActionModal name="Start" actionId={actionId} orderId={orderId} number="1" robotId={props.robotId} commandlistdata={commandlistdata}/>
                   {/* <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("1" , actionId,orderId)}>Start Action</button> */}
                  <CancelActionModel actionId={props?.robotData?.robotStatus?.actionId}  name="Cancel"  orderId={orderId} fleetId={props?.robotData?.robotStatus?.fleetId} commandlistdata={commandlistdata}/>
            </div>);
    case "1":
      return (<div className='RobotActions-button-wrapper'> 
               <RobotActionModal name="Finish" actionId={actionId} orderId={orderId} number="3" robotId={props.robotId} commandlistdata={commandlistdata}/>
             {/* <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("3",actionId,orderId)}>Finish Action</button> */}
             {/* <button className="RobotActions-cancel-btn" onClick={()=> handleCancelActionbutton(props?.robotData?.robotStatus?.actionId,orderId)}> Cancel Action</button> */}
                <CancelActionModel actionId={props?.robotData?.robotStatus?.actionId} name="Cancel" orderId={orderId} fleetId={props?.robotData?.robotStatus?.fleetId} commandlistdata={commandlistdata}/>
            </div>);
     default:
      return null
  }
 } 
  return (
    <div className='RobotActions-content-wrapper-div'>
                  
      {/* {console.log('commandlistdatacommandlistdata',commandlistdata)} */}
      {(()=>{
          setTimeout(()=>{
            setfinishbtn(true)
          }, 7000)
      })()}
      {/* {console.log('commandlistdatafdgnfnfhfm',commandlistdata)} */}
      {commandlistdata?.length >0 ?
         commandlistdata.map((item)=>{
            if(item.actionPriority === 1){
             return <><h3> {item.actionType}</h3>
                 <p> Order Id : <span>{item.orderId}</span></p> 
                  
              <p> Location :    {item.latitude ||item.longitude ?<span>{item.latitude}, {item.longitude}</span>:item.locationName }  </p> 
              <p> Compartement:<span>{item.boxId}</span></p> 
                {getrobotactionStatus(item,item.orderId,item.actionId)} 
             {(()=>{
                 if(item?.actionStatus ==='1'){
                  pausebeepsound()
                 }
                })()
             }
              </>  
            }
      }) 
      : <p> No actions to show</p>}

    </div>
  )
}

export default React.memo(RobotActions)